import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/Button"
import styles from "./styles.module.scss"
import LessonFilter from "components/Lessons/Search/Filter"
import LessonFilterHeader from "../Lessons/Search/FilterHeader"
import { useNavigate } from "react-router-dom"
import ToolTip from "components/General/ToolTip"
import { useModel } from "@stem-sims/nexus"
import GradeFilter from "components/Lessons/Search/GradeFilter"
import { gradeRangeToTier } from "../../shared/types/moduleTypes"
import modules from "shared/routes/moduleRoutes"
import { setSelectedItemDropdown, setSelectedGradeItem } from "helpers/filterItemSelect"
import { faArrowDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { Container } from "react-bootstrap"

const { landingPage, box, overlayLandingTitle, landingCover, greyishBg, logos, btn120, imgBtn, maroonBg, blueBg, navyBg, colHeader, greyBox } = styles

const helpLinkVideos = [
    {
        "title": "Teacher Dashboard Overview",
        "link": "https://youtube.com/embed/YzJBid581AI"
    },
    {
        "title": "Adding a New Class",
        "link": "https://youtube.com/embed/vqkvGZrqRQI"
    },
    {
        "title": "Editing Class Settings",
        "link": "https://youtube.com/embed/GpRR5EWzaBo"
    },
]

export default function LandingPage() {

    const navigate = useNavigate()
    const [grades, setGrades] = React.useState<{ name: string, selected: boolean, invalid: boolean }[]>(Object.keys(gradeRangeToTier).map(grade => ({ name: grade, selected: false, invalid: false })))
    const [selectedGrades, setSelectedGrade] = React.useState<string[] | null>(null)

    const [categories, setCategories] = React.useState<{ name: string, id: number, invalid: boolean, selected: boolean }[]>([])
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<number | null>(null)    

    const { response: lessonSearchResults, loading: loadingResults } = useModel({
        model: modules.list,
        props: {
            grades: selectedGrades,
            categoryId: selectedCategoryId,
        }
    })

    const searchByRedirect = () => {
        // Remove selectedGrades from localstorage so that current selected grades would be pre-selected on lesson search page
        localStorage.removeItem("selectedGrades")
        navigate("/lessons/search", { state: { grades: grades, grade: selectedGrades, category: selectedCategoryId } })
    }

    function searchByTier(grade: string) {
        localStorage.removeItem("selectedGrades")
        navigate("/lessons/search", { state: {grade: [grade], category: null } })
    }

    React.useEffect(() => {
        if(!lessonSearchResults) return
        setGrades((grades) =>
            lessonSearchResults.filters.grades.map((grade, index) => ({
                name: grade.level,
                invalid: grade.invalid,
                selected: selectedGrades === null ? false : (grades[index]?.selected ?? false) // if selected grade is null, don't check previous selected state
            }))
        )
        setCategories((categories) =>
            lessonSearchResults.filters.categories.map((category, index) => ({
                name: category.name,
                id: category.id as number,
                invalid: category.invalid,
                selected: selectedCategoryId === null ? false : (categories[index]?.selected ?? false) // if selected category is null, don't check previous selected state
            }))
        )
        // this is already updated when selectCategory and selectGrade are changed, since it changes lessonSearchResults
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },  [lessonSearchResults])

    const handleScroll = () => {
        window.scrollBy({
            top: window.innerHeight - 100, // Scroll by one page height
            left: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div id={landingPage}>
            <RelevantHelpModal videos={helpLinkVideos}/>
            <main className="bg-body container-fluid g-0 overflow-hidden">
                <div className="d-none d-lg-flex border-bottom border-secondary bg-light justify-content-start align-items-start px-5">
                    <Container className="d-flex justify-content-start align-items-start">
                        <a className="mx-5 my-2 fw-bold fs-6 text-decoration-none text-dark" href="/homeschool">For Homeschool</a>
                    </Container>
                </div>
                <div className="min-full-screen row rows-cols-sm-2 flex-row-reverse align-content-center mb-2">
                    <div id={overlayLandingTitle} className="col-lg-4 col-sm-5 col-12 text-sm-start px-5 ps-sm-1 pe-sm-5 mb-4 me-sm-3 me-lg-5">
                        <h1 className="h2"><b>Virtual Labs.</b></h1>
                        <p className="h1 pb-3 theme-color"><strong>Real STEM.</strong></p>
                        <p className="pb-3">Virtual laboratories and engineering design simulations for grades K-12.</p>
                        <div className={`${greyBox} rounded p-3 p-xl-4`}>
                            <LessonFilterHeader
                                title="Select Grade"
                                onClick={() => {
                                    setSelectedGrade(null)
                                    setSelectedCategoryId(null)
                                }}
                            />
                            <GradeFilter
                                grades={grades}
                                onSelectGrade={(index) => {
                                    setSelectedGradeItem(grades, index, setGrades, setSelectedGrade)
                                    setSelectedCategoryId(null)
                                }}
                                page="landing"
                            />
                            <br />
                            <LessonFilterHeader
                                title={"Select Category"}
                                onClick={() => setSelectedCategoryId(null)}
                            />
                            <div className="d-flex mt-2">
                                <LessonFilter
                                    type="singleselect"
                                    name="category"
                                    items={categories}
                                    onSelect={(index) => {
                                        setSelectedItemDropdown(categories, index, setCategories, setSelectedCategoryId)
                                    }}
                                    disabled={!selectedGrades}
                                    toolTip={"Select Category"}
                                    toolTipDisabled="Grade Required"
                                    loading={loadingResults}
                                />
                                <ToolTip title={!selectedGrades ? "Grade Required" : (!selectedCategoryId ? "Select Category" : "Search Lesson")}>
                                    <div> {/* div helps with disabled tooltip appearing */}
                                        <Button variant="theme" disabled={!selectedCategoryId || !selectedGrades} className={`${btn120} no-rounded-start me-3`} onClick={searchByRedirect}>Search</Button>
                                    </div>
                                </ToolTip>
                            </div>
                            <div className="text-center">
                                <Row className="d-flex py-3">
                                    <Col className="border-top border-secondary mt-3"></Col>
                                    <Col xs={3} md={2} className="text-dark mt-1">OR</Col>
                                    <Col className="border-top border-secondary mt-3"></Col>
                                </Row>
                                <div>
                                    <span>Search by </span>
                                    <a href="simulations/search/" className="theme-color text-decoration-none">Standard</a>
                                    <span> or </span> 
                                    <a href="simulations/search/" className="theme-color text-decoration-none">Textbook</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={landingCover} className=" col-lg-8 col-sm-7 col-12">
                        <img src="/images/landing/stem-sims-computer.png?v=3" className="img-fluid rounded me-lg-n5" alt="STEM sims computer" aria-label="Computer on clear background with STEM simulations loaded on the front."/>
                    </div>  
                </div>
                <Button onClick={handleScroll} 
                    className="btn btn-primary rounded-circle d-flex justify-content-center align-items-center fixed-bottom m-auto"
                    style={{height: "50px", width: "50px", bottom: "20px", opacity: "50%"}}>
                    <FontAwesomeIcon icon={faArrowDown} size="2x" style={{animation:"fa-fade 5s infinite ease-in"}} />
                </Button>
                <br/>
                <div className="d-flex justify-content-center">
                    <div className="col-xl-10 row row-cols-1 row-cols-md-3 px-5 mb-5">
                        <div className="col d-flex justify-content-center mb-5">
                            <div className={box}>
                                <i className={`fas fa-flask fa-2x ${maroonBg}`}></i>
                                <p className="d-flex align-items-center p-3">Over 350 interactive simulations of laboratory experiments and engineering design projects.</p>
                                <Button variant="theme" href="/simulations/categories">Explore Library</Button>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center mb-5">
                            <div className={box}>
                                <i className={`fas fa-chart-bar fa-2x ${blueBg}`}></i>
                                <p className="d-flex align-items-center justify-content-center p-3"><span>STEM Sims is standards-based and data-driven.<br/><br/>Students <strong>do better</strong> with STEM Sims.</span></p>
                                <Button variant="theme" href="/about/research">View Research</Button>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center mb-5">
                            <div className={box}>
                                <i className={`fas fa-check fa-2x ${navyBg}`}></i>
                                <p className="d-flex align-items-center p-3">STEM Sims is available on a subscription basis for students at school and at home.</p>
                                <Button variant="theme" href="/account/sign-up">Subscribe Today</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="row justify-content-start mb-5">
                    <div className="col-md-6 align-self-center justify-content-start mb-3 pe-xl-3">
                        <img src="/images/landing/hs-lab-partners.jpg?v=3" className="img-fluid rounded ms-n1" alt="high school lab partners" aria-label="Two high school students collaborating within a school lab computer."/>
                    </div>
                    <div className="col-md-6 align-self-center text-sm-start px-5">
                        <h2 className="h3 pb-3">How it works</h2>
                        <p className="pb-3 pe-lg-5">Interactive simulations of laboratory experiments and engineering design projects capture the characteristics of the Universal Design for Learning with highly engaging activities, accessible instructional strategies, multiple paths to solutions, and an intuitive user interface.</p> 
                        <p className="pb-3 pe-lg-5">It's not just simulations. You'll receive lessons, methods, video walkthroughs and more. All designed to support a robust, minds-on STEM curriculum.</p>
                        <Button href="/about" variant="theme">Learn More</Button>
                    </div>
                </div>
                <br/>
                <div className="flex-column justify-content-center">
                    <div className="text-center my-4 px-5">
                        <h2><b>READY TO START?</b></h2>    
                        <label>Our virtual labs cover the following grade levels below:</label>
                    </div>
                    <div className="col-md-11 col-xl-9 row row-cols-1 row-cols-sm-3 row-cols-lg-5 justify-content-center px-5 mx-auto mb-5" aria-label="Square buttons containing a student of the respective age groups' lessons.">
                        <div className="col-8 mb-3">
                            <Button onClick={() => {searchByTier("Grades 3-5")}} variant="none" className={`${imgBtn} shadow-sm justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-3-6.png')"}} aria-label="Cartoon of young elementary school student standing with backpack">
                                <div>Grades 3-5</div>
                            </Button>
                        </div>
                        <div className="col-8 mb-3">
                            <Button onClick={() => {searchByTier("Grades 6-12")}} variant="none" className={`${imgBtn} shadow-sm justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-6-8.png')"}} aria-label="Cartoon of young middle school student looking at tablet while sitting">
                                <div>Grades 6-8</div>
                            </Button>
                        </div>
                        <div className="col-8 mb-3">
                            <Button onClick={() => {searchByTier("Grades 6-12")}} variant="none" className={`${imgBtn} shadow-sm justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-9-12.png')"}} aria-label="Cartoon of young high school student sitting with tablet">
                                <div>Grades 9-12</div>
                            </Button>
                        </div>
                        <div className="col-8 mb-3">
                            <Button onClick={() => {searchByTier("Advanced Placement (AP)")}} variant="none" className={`${imgBtn} justify-content-center`} style={{backgroundImage: "url('/images/landing/cartoon-child-ap.png')"}} aria-label="Cartoon of young college student walking with backpack">
                                <div>AP</div>
                            </Button>
                        </div>
                    </div> 
                </div>
                <br/>
                <div className="row justify-content-end mb-1 m-auto my-5">
                    <div className="col-md-6 col-xl-5 align-self-center text-sm-end mb-3 px-5">
                        <h2 className="h3 mb-4 ps-lg-5">Preparing Teachers</h2>
                        <p className="mb-4 ps-lg-5">
                            Our team has successfully delivered hundreds of professional development workshops and has more than 70 combined years of STEM teaching experience at the elementary, secondary, and post-secondary levels.<br/>
                        </p>
                        <Button variant="theme" href="mailto:sales@stemsims.com?subject=Professional%20Development%20Inquiry" className="mb-3" >Contact Us</Button>
                    </div>
                    <div className="col-md-6">
                        <img src="/images/landing/hs-students-collab.jpg?v=3" className="img-fluid rounded me-n1" alt="high school students" aria-label="Three high school students gather around computer and point at screen."/>
                    </div>
                </div>
                <br/><br/>
                <div className="row row-cols-1 row-cols-sm-2 justify-content-center position-relative px-5 my-5">
                    <div className="col-10 col-sm-6 col-md-5 col-xl-4 px-3 px-xxl-5 mb-3">
                        <div className={`${logos}`} role="img" aria-label="Logos of companies with research that supports STEM Sims: DOHL, NSF, and NIH">
                            <div className="px-xl-3">
                                <img className="col-6 img-fluid" src="/images/landing/dohl-logo.png?v=2" alt="DOHL logo" aria-label="DOHL logo"/>
                                <img className="col-6 img-fluid" src="/images/landing/nsf-logo.png" alt="NSF logo" aria-label="NSF logo"/>
                            </div>
                            <img src="/images/landing/nih-logo.png" className="img-fluid px-xl-3" alt="NIH logo" aria-label="NIH logo"/>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-5 align-self-center text-sm-start px-md-5">
                        <h2 className={`${colHeader} h3 mb-2`}>Research Backed</h2>
                        <p className="pb-3 pe-lg-5">
                            STEM Sims is research-based. That's important because of the learning gains achieved by STEM Sims users.
                            <br/>
                            <br/>
                            Studies conducted under NSF, NIH, DHS, and continuing projects clearly demonstrate that students do better with STEM Sims. 
                        </p>
                        <Button variant="theme" href="/account/sign-up">Get Started Today</Button>
                    </div>
                </div>
                <br/>
                <div className="vw-100 mt-5">
                    <Carousel id="landingCarousel" className="carousel slide" style={{backgroundImage: "url('/images/landing/hex-back.jpg')"}}>
                        <Carousel.Item>
                            <p className="h4"><b>"</b>I can see that these modules could spark the interest even in those students who dare us to make them interested in science.<b>"</b></p>
                            <p>- 7th Grade Integrated Science Teacher, Orlando, Florida</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <p className="h4"><b>"</b>Students improved their pre- to post-test scores by more than 45% after they used the STEM Sims Calorimetry module.<b>"</b></p>
                            <p>— Grade 7 science students in Baltimore, Maryland</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <p className="h4"><b>"</b>In K-12 education, most STEM teaching and learning focuses on science or mathematics, while comparatively little attention has been paid to technology and engineering.<b>"</b></p>
                            <p>— Margaret Honey, Chair, STEM Integration in K-12 Education Committee, National Academy of Engineering</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <p className="h4"><b>"</b>Students went from a pre-test average score of 73.2% to a post-test score of 90.4% after they used the Data Analysis simulation. The tests covered experimental design factors, such as independent and dependent variables.<b>"</b></p>
                            <p>— Science teacher, Daytona Beach, Florida</p>
                        </Carousel.Item>
                        <Carousel.Item>
                            <p className="h4"><b>"</b>We did the Trench Dive simulation, and I have to tell you how AMAZING it was! The kids enjoyed it! They were ALL engaged the entire block period (90 minutes). We did all 5 lessons. We talked and discussed water, sunlight, the different ocean levels, and so much more. I REALLY liked the background information. I can't wait to do the next one!<b>"</b></p>
                            <p>— Science teacher, North Palm Beach, Florida</p>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className={`row row-cols-sm-2 ${greyishBg} justify-content-center`}>
                    <br/>
                    <div className={`${logos} col-10 col-sm-6 col-md-4 col-lg-4 col-xl-3 px-3 px-xxl-5 mb-3`}>
                        <img src="/images/landing/google-logo.png" className="img-fluid px-2" alt="google logo" aria-label="google logo"/>
                        <img src="/images/landing/canvas-logo.png" className="img-fluid" alt="canvas logo" aria-label="canvas logo"/>
                        <img src="/images/landing/clever-logo.png" className="img-fluid px-3" alt="clever logo" aria-label="clever logo"/>
                        <img src="/images/landing/schoology-logo.png" className="img-fluid mt-n1 pe-1" alt="schoology logo" aria-label="schoology logo"/>
                    </div>
                    <div className="col-sm-6 col-xl-5 text-sm-start align-self-center px-md-5">
                        <h2 className="h3 pb-3">Easy Integration</h2>
                        <p className="pb-3">
                            New login methods make accessing STEM Sims effortless, without compromising security. Our integrations with e-learning are simpler than ever, so you can easily merge our content and resources into the learning environments you already have set up.
                        </p>
                        {/*
                            Readd button after page is designed. See task STEM-507
                            <Button variant="theme" className="">Access and Integration</Button>
                        */}
                    </div>
                    <br/>
                </div>
                <br/>
                <div className="row justify-content-center d-flex m-auto my-5">
                    <h2 className="col-12 text-center pb-3"><b>Interested in STEM Sims for your classroom?</b></h2>
                    <p className="pb-3 col-9 text-center">Try our program with a <strong><a href="/account/sign-up/">10 day free trial!</a></strong> Then we can guide you through the next steps together.</p>
                </div>
            </main>
        </div>
    )
}
