import { Button, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../shared/routes/teacher"
import { AssignmentResponse } from "../../../../shared/types/teacherTypes"

export interface Props {
    assignment: AssignmentResponse | null
    show: boolean
    onHide: () => void
    onSuccess: () => void
}

export default function DeleteAssignment({ assignment, show, onHide, onSuccess }: Props) {
    return(
        <Modal show={show} onHide={onHide}>
            <Modal.Header className="border-0" closeButton>
                <h2 className="h3 w-100 text-center">Delete Assignment</h2>
            </Modal.Header>
            <Modal.Body className="mx-5 text-start">
                <p>Deleting this assignment will also delete all student submissions for this assignment.</p>
                <p><strong>Are you sure you would like to delete {assignment?.title}?</strong></p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="theme" onClick={onHide}>Cancel</Button>
                <Button
                    variant="danger"
                    onClick={() => {
                        return teacher.deleteAssignment({
                            id: assignment?.id
                        })
                            .then(() => {
                                onHide()
                                toast.success("The assignment has successfully been deleted.")
                                onSuccess()
                            })
                            .catch(() => {
                                onHide()
                                //TODO: (STEM-521) Could use some client side error reporting
                                toast.error("There has been an error deleting the assignment, please try again.")
                            })
                    }}
                >
                    Delete Assignment
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
