import axios from "axios"

/**
 * Login teacher account
 */
export interface LogInProps {
    username: string
    password: string
    trialCode?: string
    oAuthCode?: string
    oAuthState?: string
    referer?: string
    pageHash?: string
}

export function login({ username, password, trialCode, oAuthCode, oAuthState, referer, pageHash } : LogInProps) {
    return axios.post("/api/login", { username, password, trialCode: trialCode, oAuthCode, oAuthState, referer: referer, pageHash: pageHash })
}

/**
 * Signup teacher account
 */
export function signup({  name, username, email, password, confirmPassword, ssoId }) {
    return axios.post("/api/signup", { name, username, email, password, confirmPassword, ssoId })
}

export async function oauth({ providerId }) {
    const res = await axios.get(`/api/account/oauth-login?providerId=${encodeURIComponent(providerId)}`)
    return res.data
}

export async function googleSSOInfo() {
    const res = await axios.get("/api/account/google-sso-info")
    return res.data
}

/* 
Send an email with a link to reset teacher password
*/
export async function recoverTeacherAccount({ email }: { email: string }) {
    const res = await axios.post("/api/account/recover-account", { email })
    return res.data
}

/* 
Reset teacher password
*/
export async function resetTeacherPassword({ password, confirmPassword, code }: { password: string, confirmPassword: string, code: string }) {
    const res = await axios.post("/api/account/reset-password", {password, confirmPassword, code})
    return res.data
}
