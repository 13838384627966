import { CurriculumUnit } from "shared/types/curriculumTypes";
import { useState, useEffect, useContext } from "react";
import FloatingPage from "../General/FloatingPage";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { useParams } from "react-router";
import { Accordion, Button, Card, Row, Col } from "react-bootstrap";
import styles from "./Curriculum.module.scss";
import { AuthContext } from "AuthContext";
import { BookContext } from "./BookContext";

export default function BookUnitsPage() {
    const authInfo = useContext(AuthContext)
    const bookInfo = useContext(BookContext)
    const textbooks = bookInfo.textbooks

    const { bookId } = useParams()

    const currentBook = textbooks.find((book) => book.id === bookId)

    const [bookUnits, setBookUnits] = useState<CurriculumUnit[]>([])
    const [activeUnit, setActiveUnit] = useState(null)
    const [activeTopic, setActiveTopic] = useState(null)
    const [bookError, setBookError] = useState(false)

    useEffect(() => {
        axios.get(`/api/curriculum/units?bookId=${bookId}`).then(response => {
            const sortedBookUnits = response.data.sort((a, b) => a.name.localeCompare(b.name))
            setBookUnits(sortedBookUnits)
            if (sortedBookUnits.length > 0) {
                setActiveUnit(sortedBookUnits[0].id)
            }
            if (response.status !== 200) {
                setBookError(true)
            }
        }).catch(error => {
            setBookError(true)
        })
    }, [bookId])


    return (
        <FloatingPage className={styles.background}>
            {bookError ? (
                <>
                    <h2>There was an error loading the book. Please return to the <a href="/books">textbook page</a> and try again.</h2>
                </>
            ) : (
                <>
                    {currentBook && (
                        <div className="d-flex p-3">
                            {currentBook.image && <img src={currentBook.image} alt={currentBook.name} height="100px" className="me-3"/>}
                            <div className="d-flex flex-column justify-content-center text-start">
                                <h1>{currentBook.name}</h1>
                                <p>{currentBook.description}</p>
                            </div>
                        </div>
                    )}
                    <h1>Table Of Contents</h1>
                    {currentBook === null || activeUnit === null ? (
                        <BeatLoader size={10} speedMultiplier={0.8} />
                    ) : (
                        <Accordion className="my-5 text-start" activeKey={activeUnit}>
                            {bookUnits.map((unit) => (
                                <Card key={unit.id} onClick={() => {
                                    setActiveUnit(unit.id)
                                }}>
                                    <Card.Header className="bg-light fs-4 fw-bold" >{unit.name}</Card.Header>
                                    <Accordion.Collapse eventKey={unit.id}>
                                        <Card.Body className="p-4">
                                            {unit.description}
                                            {unit.topics && unit.topics.length > 0 && (
                                                <Accordion activeKey={activeTopic} className="mt-4">
                                                    {unit.topics.map((topic) => (
                                                        <Card key={topic.id} onClick={() => {
                                                            if (topic.description) {
                                                                setActiveTopic(topic.id)
                                                            } else {
                                                                setActiveTopic(null)
                                                            }
                                                        }}>
                                                            <Card.Header className="fs-5 fw-bold">
                                                                <Row className="justify-content-between">
                                                                    <Col md={7}>
                                                                        {topic.image && <img src={topic.image} alt="" className="me-4 object-fit-cover" width="50" height="50" />}
                                                                        {topic.name}
                                                                    </Col>
                                                                    <Col md={5} className="text-end d-flex align-items-center justify-content-end">
                                                                        {/* TKK: Add links for Add/View Assignment and View Topic */}
                                                                        {!authInfo.isStudent && <Button variant="primary" href={`/api/curriculum/topic/answer-key?topicId=${topic.id}`}>Answer Key</Button>}
                                                                        <Button variant="primary" className={`${!authInfo.isStudent && "ms-3"}`} href={`/api/curriculum/topic/pdf?topicId=${topic.id}`}>View Topic</Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey={topic.id}>
                                                                <Card.Body>{topic.description}</Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    ))}
                                                </Accordion>
                                            )}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    )}
                </>
            )}
        </FloatingPage>
        );
}
