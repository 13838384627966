import * as React from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "../teacher.module.scss"
import * as teacher from "../../../../shared/routes/teacher"
import { AssignmentResponse, ClassResponse } from "../../../../shared/types/teacherTypes"
import DeleteAssignment from "../Forms/DeleteAssignment"
import ToolTip from "../../../General/ToolTip"
import { useModel } from '@stem-sims/nexus'
import lessons from "shared/lessons"
import { TeacherGradesState } from "components/Lessons/Grading/GradeList/TeacherGrades"
import Assignments from "components/Dashboards/General/Assignments"
import EmptyTablePreview from "../../General/EmptyTablePreview"
import RelevantHelpModal from "components/Modals/RelevantHelpModal"
import { helpLinkVideos, helpSteps } from "./assignmentsHelpData"

interface Props {
    activeClass: ClassResponse
    refreshClasses: () => void
}

function NoAssignmentsView({onClickAdd}: {onClickAdd: () => void}) {
    return (
        <>
            <EmptyTablePreview
                title="Create Assignments"
                description="Once you add an assignment the entire class will be able to see and submit it."
            >
                <Button 
                    variant="theme"
                    onClick={onClickAdd}
                    data-testid="empty-add-assignment"
                >
                    + Add Assignments
                </Button>
            </EmptyTablePreview>
        </>
    )
}

export default function TeacherAssignments({ activeClass, refreshClasses }: Props) {
    const [deleteAssignmentShown, setDeleteAssignmentShown] = React.useState(false)
    const [assignmentToBeDeleted, setAssignmentToBeDeleted] = React.useState<AssignmentResponse | null>(null)
    const navigate = useNavigate()

    function assignmentError(err) {
        toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")
        refreshClasses()
    }

    const { response: assignments, loading, refreshModel: reloadAssignments} = useModel({
        model: teacher.getAssignments,
        onError: assignmentError,
        props: { classID: activeClass.id }
    })

    React.useEffect(() => {
        reloadAssignments()
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [activeClass])

    const extraCols = [
        {
            id: 'Actions',
            disableSortBy: true,
            Cell: ({ row }) => (
                <>
                    <ToolTip title="Edit Assignment">
                        <Button
                            className="me-5 class-assignment-edit"
                            variant="warning"
                            aria-label="Edit Assignment"
                            disabled={!row.original.userHasAccess}
                            onClick={(e) => {
                                e.stopPropagation()
                                navigate("/dashboard/teacher/assignments/edit", {
                                    state: {
                                        assignmentId: row.original.id,
                                        lessonId: row.original.lessonId,
                                    }
                                })
                            }}
                        >
                            <i className="fas fa-edit" />
                        </Button>
                    </ToolTip>
                    <ToolTip title="Delete Assignment">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                setAssignmentToBeDeleted(row.original)
                                setDeleteAssignmentShown(true)
                            }}
                            variant="danger"
                            aria-label="Delete Assignment"
                        >
                            <i className="fas fa-trash-alt" />
                        </Button>
                    </ToolTip>
                </>
            ),
        }
    ]

    const onRowClick = async (row) => {
        const assignment: AssignmentResponse = row.original
        if (assignment.lessonVariety === "Guided") {
            if(assignment.lessonId) {
                var lesson = await lessons.findById(assignment.lessonId)
            }
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment,
                lesson: lesson
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        } else if (assignment.lessonVariety === "Pure Inquiry") {
            const TeacherGradesState: TeacherGradesState = {
                assignment: assignment  
            }
            navigate("/dashboard/teacher/assignments/grading", {
                state: TeacherGradesState
            })
        }
    }

    const changeBackgroundColor = (row) => {
        const assignment: AssignmentResponse = row.original
        return assignment.userHasAccess ? "" : `${styles.unavailableAssignment}`
    }

    const onClickAddAssignments = () => {
        // reset selectedGradeInit and initStandardId filters so that grade level and standard id from current class would be pre-selected in lesson search page
        localStorage.removeItem("selectedGrades") 
        localStorage.removeItem("selectedStandardId")
        navigate("/dashboard/teacher/assignments/search")
    }

    const AssignmentsComponent = <Assignments assignments={assignments} onRowClick={onRowClick} changeBackgroundColor={changeBackgroundColor} extraCols={extraCols} loading={loading} rowTooltip="Grade Assignment" />
    const EmptyTableComponent = <NoAssignmentsView onClickAdd={onClickAddAssignments} />
    return (
        <>
            <RelevantHelpModal videos={helpLinkVideos} helpSteps={assignments?.length > 0 && helpSteps}/>
            <h1 className="h2 text-start mt-3">Class Assignments</h1>
            <p className={`text-start ${styles.greyText}`}>
                Click row below to grade an assignment or use corresponding buttons to edit or delete an assignment.
            </p>
            <div>
                {!loading && assignments.length > 0 && <Button 
                    className="float-start position-relative move-front mb-n5 class-assignment-add"
                    onClick={onClickAddAssignments}
                    data-testid="table-add-assignment"
                    variant="theme"
                >+ Add Assignments</Button>}
            </div>
            {(loading || assignments?.length > 0) ? AssignmentsComponent : EmptyTableComponent}
            <DeleteAssignment
                assignment={assignmentToBeDeleted}
                show={deleteAssignmentShown}
                onHide={() => setDeleteAssignmentShown(false)}
                onSuccess={() => {
                    reloadAssignments()
                }}
            />
        </>
    )
}
