
import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'

export function gotoCheckout(productName: string, quantity: number = 1) {
    axios.get(`/api/stripe/checkout-session?productName=${productName}&quantity=${quantity}`)
        .then(response => {
            loadStripe(process.env.REACT_APP_STRIPE_PUBLIC).then(stripe => {
                stripe.redirectToCheckout({ sessionId: response.data.id })
            })
        })
}
