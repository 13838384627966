import { Col, Row } from "react-bootstrap"
import Pane from "../General/Pane"
import styles from "./Student.module.scss"
import { toast } from "react-toastify"
import { StudentAssignmentResponse } from "shared/types/studentTypes"
import { formatToLongDate } from "helpers/dateHelper"
import { beforeCurrentDay, dueSoon } from "helpers/dateHelper"
import { getStudentSubmissionId } from "helpers/getSubmissionId"
import { useNavigate } from "react-router"
import { HourglassSplit, HourglassBottom } from "react-bootstrap-icons"
import { AssignmentResponse } from "shared/types/teacherTypes"
import { useContext } from "react"
import { AuthContext } from "AuthContext"
import lessons from "shared/lessons"
import { TeacherGradesState } from "components/Lessons/Grading/GradeList/TeacherGrades"
import ToolTip from "components/General/ToolTip"

interface Props {
    filteredAssignments: StudentAssignmentResponse[] | AssignmentResponse[]
}

const AssignmentsOverview = ({ filteredAssignments } : Props) => {
    const navigate = useNavigate()
    const { isStudent } = useContext(AuthContext)

    const openAssignment = async (assignment: StudentAssignmentResponse | AssignmentResponse) => {

        if (isStudent) {
            try {
                const studentSubmissionId = await getStudentSubmissionId(assignment as StudentAssignmentResponse)
                navigate("/dashboard/student/assignments/assignment", {
                    state: {
                        submissionId: studentSubmissionId,
                        assignment: assignment,
                    }
                })
            } catch (err) {
                toast.error(err?.response?.data?.message ?? "Unable to start assignment, please try again.")
            }
        } else {
            if (assignment.lessonVariety === "Guided") {
                if(assignment.lessonId) {
                    var lesson = await lessons.findById(assignment.lessonId)
                }
                const TeacherGradesState: TeacherGradesState = {
                    assignment: assignment,
                    lesson: lesson
                }
                navigate("/dashboard/teacher/assignments/grading", {
                    state: TeacherGradesState
                })
            } else if (assignment.lessonVariety === "Pure Inquiry") {
                const TeacherGradesState: TeacherGradesState = {
                    assignment: assignment  
                }
                navigate("/dashboard/teacher/assignments/grading", {
                    state: TeacherGradesState
                })
            }
        }
    }

  return (
    <div className={`mt-4 p-2 ${styles.assignmentsListContainer}`}>
        {filteredAssignments?.map((assignment: StudentAssignmentResponse | AssignmentResponse) => {
                const isDueSoon = dueSoon(assignment.dueDate)
                const isPastDue = beforeCurrentDay(assignment.dueDate)

                return <ToolTip title={isStudent ? "Start assignment" : "Grade assignment"}>
                    <div key={assignment.id} onClick={() => openAssignment(assignment)}>
                            <Pane className={`shadow-sm mt-2 ${styles.assignmentOverview}`}>
                                <Row>
                                    <Col className="text-start" sm={12} md={7}>
                                        <p className={`fw-bold ${styles.assignmentTitle}`}>
                                            {assignment.title}
                                        </p>
                                        <p className="text-muted fs-6 fw-bold mb-0">{assignment.moduleName}</p>
                                    </Col>
                                    <Col className={`text-start ${styles.dueDateContainer}`} sm={12} md={5}>
                                        {assignment.dueDate && (
                                            <>
                                                <p className={`m-0`}>
                                                    <span className="fw-bold">Due:&nbsp;</span><span className={`text-success ${isPastDue && `text-danger`}`}>{formatToLongDate(assignment.dueDate)}</span>
                                                </p>
                                                {(isDueSoon || isPastDue) && 
                                                    <p className={`${styles.dueStatus} ${isDueSoon && `bg-success`} ${isPastDue && `bg-danger`}
                                                        fw-bold text-nowrap text-white mb-0 px-3 py-1 rounded text-center fw-bold d-inline-block mt-1`}>
                                                        {isDueSoon && <><HourglassSplit className="me-2" /> due soon</>}
                                                        {isPastDue && <><HourglassBottom className="me-2" /> past due</>}
                                                    </p>
                                                }
                                            </>)}
                                    </Col>
                                </Row>
                            </Pane>
                </div>
                </ToolTip>})
        }
    </div>
  )
}

export default AssignmentsOverview
