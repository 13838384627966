import * as React from "react"
import styles from "./sidebar.module.scss"
import { SidebarContext } from "components/Sidebar/SidebarContext"

const SidebarContainer: React.FC = ({children}) => {

    // Toggle Sidebar between collapse and expand modes
    const [collapsed, setCollapsed] = React.useState<boolean>(false)

    const toggleCollapse = (): void => {
        setCollapsed(!collapsed)
    }

    // add resize event handler to screen to get current screen width
    React.useEffect(() => {
        if (window.innerWidth <= 430) {
            setCollapsed(true)
        }
    }, []);

    
    return <div className={`d-flex ${styles.dashboard}`}>
        <SidebarContext.Provider value={{ collapsed, toggleCollapse }}>
            { children }
        </SidebarContext.Provider>
    </div>
}

export default SidebarContainer
