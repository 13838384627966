import React from "react"
import "bootstrap"
import getURLParams from "../helpers/getURLParams"
import removeMessageQuery from "../helpers/removeMessageQuery"
import Container from "react-bootstrap/Container"
import NavDropdown from "react-bootstrap/NavDropdown"
import { NavItem, NavLink } from "react-bootstrap"
import LogoutWarning from "./Logout/LogoutWarning"

type Props = {
    isLoggedIn: boolean
    isStudent: boolean
    isAdmin: boolean
    isStaff: boolean
    trackingEnabled: boolean
    project: string
    csrfToken: string
    hasCurriculumAccess: boolean
}

export default function Header({ project, isLoggedIn, isAdmin, isStaff, isStudent, trackingEnabled, csrfToken, hasCurriculumAccess }: Props) {
    const [ params ] = React.useState<{ success?: string, error?: string, info?: string }>(getURLParams())
    const cleanedUrl = new URL(removeMessageQuery(window.location.href))
    window.history.replaceState({}, "", cleanedUrl.href)
    const cleanedRelativePath = `${cleanedUrl.pathname}${cleanedUrl.search}${cleanedUrl.hash}`
    const editing = process.env.REACT_APP_EDITING === "true"

    return <>
        <LogoutWarning isLoggedIn={isLoggedIn} />
        <img
            className="d-none"
            width="75" height="75"

            src="/images/logo.png"

            alt={`${project} Logo`} />
        <nav className="navbar bg-light navbar-light navbar-expand-lg fixed-top px-1 py-0">
            <div className="navbar-image navbar-brand navbar-brand-default">
                <a href="/">
                    <img
                        src="/images/logo.png"
                        alt={`${project} Logo`}
                        title="Home" />
                </a>
            </div>

            <Container className="margin-container" fluid="xl">
                <div className="navbar-header my-3 d-print-none">
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar-collapse" aria-expanded="false" aria-label="main menu">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>

                <div className="collapse navbar-collapse" id="navbar-collapse">
                    <div className="navbar-nav me-auto">
                        {!isLoggedIn &&
                            <div className="nav-item">
                                <a className="btn btn-primary navbar-btn" href="/account/sign-up">Sign Up</a>
                            </div>
                        }
                        {!isStudent &&
                            <NavDropdown title="Resources" id="about-dropdown" className='px-2'>
                                <NavDropdown.Item href="/about">About</NavDropdown.Item>
                                <NavDropdown.Item href="/help">Help</NavDropdown.Item>
                            </NavDropdown>
                        }
                        <NavItem>
                            <NavLink href="/lessons/search">Simulations</NavLink>
                        </NavItem>
                        {isLoggedIn && !isStudent && trackingEnabled &&
                            <div className="nav-item">
                                <a className="nav-link" href="/dashboard/teacher">Classes</a>
                            </div>
                        }
                        {isLoggedIn && isStudent && trackingEnabled &&
                            <div className="nav-item">
                                <a className="nav-link" href="/dashboard/student/assignments">Assignments</a>
                            </div>
                        }
                        {isAdmin &&
                            <div className="nav-item">
                                <a className="nav-link" href="/dashboard/district">Analytics</a>
                            </div>
                        }
                        {isStaff &&
                            <div className="nav-item">
                                <a className="nav-link" href="/admin">Admin</a>
                            </div>
                        }
                        {hasCurriculumAccess &&
                            <div className="nav-item">
                                <a className="nav-link" href="/books">Textbooks</a>
                            </div>
                        }
                    </div>

                    <div className="navbar-nav ms-auto align-items-center">
                        {editing && 
                            <NavItem>
                                <NavLink href="/simulations/categories">Categories</NavLink>
                            </NavItem>
                        }
                        {!isStudent &&
                            <div className="nav-item">
                                <a className="nav-link" href="/subscribe">Pricing</a>
                            </div>
                        }
                        {isLoggedIn && !isStudent &&
                                <div className="nav-item">
                                    <a className="nav-link" href="/account">Account</a>
                                </div>
                        }
                        {isLoggedIn ?
                            <form id="log-out-form" className="form-inline prevent-next-submit" noValidate method="post" action="/account/log-out">
                                <input type="hidden" name="redirect-uri" id="log-out-redirect-uri" value={cleanedRelativePath} />

                                <input type="hidden" name="csrf-token" id="log-out-csrf-token" value={csrfToken} />
                                <div className="nav-item">
                                    <button id="logout-button" type="submit" className="btn btn-default navbar-btn">Log Out</button>
                                </div>
                            </form>
                            :
                            <div className="nav-item">
                                <a id="log-in-button" className="btn btn-primary navbar-btn" href="/account/log-in">Log In</a>
                            </div>
                        }
                        <div className="nav-item">
                            <div id="google_translate_element"></div>
                        </div>
                    </div>
                </div>
            </Container>
        </nav>
        <div role="region" className="container-fluid margin-container max-width padding-header">
            <div className="row">
                { params.error &&
                    <div className="alert alert-danger">{params.error}</div>
                }
                { params.success &&
                    <div className="alert alert-success">{params.success}</div>
                }
                { params.info &&
                    <div className="alert alert-info">{params.info}</div>
                }
            </div>
        </div>
    </>
}
