import axios from "axios";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { CurriculumBook } from "shared/types/curriculumTypes";
import TextbookPage from "./TextbookPage";
import { BookContext } from "./BookContext";
import BookUnitsPage from "./BookUnitsPage";

export default function BookPage() {
    const [textbooks, setTextbooks] = useState<CurriculumBook[]>([])

    // Load textbooks from database
    useEffect(() => {
        axios.get("/api/curriculum/books")
            .then((response) => {
                setTextbooks(response.data)
            })
            .catch((error) => {
                console.error("Error fetching textbooks:", error);
            });
    }, []);

    return <BookContext.Provider value={{ textbooks: textbooks }}>
        <Routes>
            <Route path="" element={<TextbookPage />} />
            <Route path="/units/:bookId" element={<BookUnitsPage />} />
        </Routes>
    </BookContext.Provider>
}
