import * as React from "react"
import { Tooltip, OverlayTrigger } from "react-bootstrap"

export default function ToolTip({title, children}) {
    return (
        <OverlayTrigger
            overlay={(props) => (
                <Tooltip {...props}>
                    {title}
                </Tooltip>
            )}
        >
            {children}
        </OverlayTrigger>
    )
}
